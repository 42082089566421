<script lang="ts">
  /* Global dependencies */
  import { onMount } from "svelte";
  import { paginate, LightPaginationNav } from "svelte-paginate";

  /* GSFT dependencies */

  import Layout from "../components/Layout.svelte";
  import { getGrandSlamUsers, getUsers } from "../api/users";
  import AddEditUser from "../components/users/AddEditUser.svelte";
  import DeleteUserModal from "../components/users/DeleteUserModal.svelte";
  import ResendUserPasswordModal from "../components/users/ResendUserPasswordModal.svelte";
  import ToggleUserToCurrentGrandSlamRelationModal from "../components/users/ToggleUserToCurrentGrandSlamRelationModal.svelte";
  import type { GsUser } from "../types/users";

  import { user as loggedInUser, isSuperAdmin } from "../store/user";

  const ROLE_UMPIRE = "Chair Umpire";
  const ROLE_SLAM_ADMIN = "Grand Slam Admin";
  const ROLE_SLAM_SUPERVISOR = "Grand Slam Supervisor";
  const ROLE_SYS_ADMIN = "SysAdmin";

  const USERS_PER_PAGE = 10;
  const DISPLAYED_ROLES = [
    ROLE_UMPIRE,
    ROLE_SLAM_ADMIN,
    ROLE_SLAM_SUPERVISOR,
    ROLE_SYS_ADMIN,
  ];
  const ACTION_ADD = "ADD";
  const ACTION_EDIT = "EDIT";
  const ACTION_REMOVE = "REMOVE";

  /* Props */
  let filter: string;

  let allUsers: Array<GsUser> = [];
  let filteredUsers: Array<GsUser> = [];
  let currentPage = 1;
  let actionBoundUser: GsUser = null;
  let openAddUser = false;
  let currentAction = ACTION_ADD;
  let toggleSlamRelationAction;
  let isDeleteUserConfirmationShown = false;
  let isToggleUserRelationToCurrentGrandSlamConfirmationShown = false;
  let isResetTempPasswordConfirmationShown = false;
  $: paginatedItems = paginate({
    items: filteredUsers,
    pageSize: USERS_PER_PAGE,
    currentPage,
  });

  /* Component */
  onMount(async () => {
    await loadUsers();
  });

  const loadUsers = async () => {
    const activeGrandSlamUsersIds = (await getGrandSlamUsers()).map(
      (user) => user.id
    );
    allUsers = (await getUsers()).map((user) => ({
      ...user,
      belongsToCurrentGrandSlam: activeGrandSlamUsersIds.includes(user.id),
    }));
    filteredUsers = [...allUsers];
  };

  const onCloseActionDialog = async () => {
    actionBoundUser = null;
  };

  const searchUsers = () => {
    if (!filter) {
      filteredUsers = [...allUsers];
      currentPage = 1;
      return;
    }
    const filterLower = filter.toLowerCase();
    filteredUsers = allUsers.filter(
      (f) =>
        f.firstName.toLowerCase().includes(filterLower) ||
        f.lastName.toLowerCase().includes(filterLower) ||
        f.email.toLowerCase().includes(filterLower)
    );
    currentPage = 1;
  };

  const addUser = () => {
    actionBoundUser = null;
    openAddUser = true;
    currentAction = ACTION_ADD;
  };

  const editUser = (index) => {
    openAddUser = true;
    actionBoundUser = paginatedItems[index];
    currentAction = ACTION_EDIT;
  };

  const showDeleteUserConfirmation = (index) => {
    isDeleteUserConfirmationShown = true;
    actionBoundUser = paginatedItems[index];
  };

  const showToggleUserRelationToCurrentGrandSlamConfirmation = (index, add) => {
    isToggleUserRelationToCurrentGrandSlamConfirmationShown = true;
    toggleSlamRelationAction = add ? ACTION_ADD : ACTION_REMOVE;
    actionBoundUser = paginatedItems[index];
  };

  const showResetTempPasswordConfirmation = (index) => {
    isResetTempPasswordConfirmationShown = true;
    actionBoundUser = paginatedItems[index];
  };
</script>

<Layout>
  <div class="row mt-2">
    <div class="col-md-6">
      Filter Users:
      <input
        type="text"
        class="form-inline form-control"
        placeholder="Find User by Name, Email"
        on:keyup={searchUsers}
        bind:value={filter}
      />
    </div>
    <div class="col-md-1 offset-5 mr-1">
      <button class="float-right" on:click={addUser}>Add User</button>
    </div>
  </div>
  <div class="overflow-auto">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col" class="col-status">Status</th>
          <th scope="col" class="col-title">First Name</th>
          <th scope="col" class="col-title">Last Name</th>
          <th scope="col" class="col-title">Role</th>
          <th scope="col" class="col-title">Email</th>
          <th scope="col" class="col-status">Current GS</th>
          <th scope="col" class="col-title">Actions</th>
        </tr>
      </thead>
      <tbody>
        {#if filteredUsers?.length}
          {#each paginatedItems as user, i}
            <tr class={user.active ? "" : "inactive-user"}>
              <td>{user.active ? "Active" : "Inactive"}</td>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>{user.role}</td>
              <td>{user.email}</td>
              <td>{user.belongsToCurrentGrandSlam ? "Yes" : "No"}</td>
              <td>
                {#if user.active}
                <button
                  class={user.belongsToCurrentGrandSlam
                    ? "fas fa-minus-circle"
                    : "fas fa-plus-circle"}
                  title={`${
                    user.belongsToCurrentGrandSlam
                      ? "Remove user from the current slam"
                      : "Add user to the current slam"
                  }`}
                  on:click={() => {
                    showToggleUserRelationToCurrentGrandSlamConfirmation(
                      i,
                      !user.belongsToCurrentGrandSlam
                    );
                  }}
                  disabled={[ROLE_UMPIRE, ROLE_SYS_ADMIN].includes(user.role) || $loggedInUser.email === user.email || user.role === ROLE_SYS_ADMIN && !$isSuperAdmin}
                />
                <button
                  class="fas fa-edit"
                  title="Edit user"
                  on:click={() => {
                    editUser(i);
                  }}
                  disabled={user.role === ROLE_UMPIRE || user.role === ROLE_SYS_ADMIN && !$isSuperAdmin}
                />
                <button
                  title={user.belongsToCurrentGrandSlam ? "Remove user from current slam before deleting them" : ""}
                  class="fas fa-trash"
                  disabled={user.role === ROLE_UMPIRE
                    || user.belongsToCurrentGrandSlam
                    || $loggedInUser.email === user.email
                    || user.role === ROLE_SYS_ADMIN && !$isSuperAdmin
                  }
                  on:click={() => {showDeleteUserConfirmation(i)}}
                />
                <button
                  class="fas fa-envelope"
                  title="Resend invitation email"
                  on:click={() => showResetTempPasswordConfirmation(i)}
                />
                {/if}
              </td>
            </tr>
          {/each}
        {:else}
          <tr>
            <td class="text-center" colspan="6">No Users to display</td>
          </tr>
        {/if}
      </tbody>
    </table>
    <LightPaginationNav
      totalItems={filteredUsers.length}
      pageSize={USERS_PER_PAGE}
      {currentPage}
      limit={1}
      showStepOptions={true}
      on:setPage={(e) => (currentPage = e.detail.page)}
    />
  </div>
  {#key actionBoundUser?.id}
    <AddEditUser
      bind:open={openAddUser}
      user={actionBoundUser}
      action={currentAction}
      on:reloadUsers={loadUsers}
      on:close={onCloseActionDialog}
    />
  {/key}
  <DeleteUserModal
    bind:open={isDeleteUserConfirmationShown}
    bind:user={actionBoundUser}
    on:reloadUsers={loadUsers}
    on:close={onCloseActionDialog}
  />
  <ToggleUserToCurrentGrandSlamRelationModal
    bind:action={toggleSlamRelationAction}
    bind:open={isToggleUserRelationToCurrentGrandSlamConfirmationShown}
    bind:user={actionBoundUser}
    on:reloadUsers={loadUsers}
    on:close={onCloseActionDialog}
  />
  <ResendUserPasswordModal
    bind:open={isResetTempPasswordConfirmationShown}
    bind:user={actionBoundUser}
    on:close={onCloseActionDialog}
  />
</Layout>
<style>
  .inactive-user {
    opacity: .4;
  }
</style>